import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import Navigation from '../components/navigation'
import styled, { css } from 'styled-components'
import YouTube from 'react-youtube';
import EmailCtaSection from '../components/email-signup/email-cta-section'

class VideoTemplate extends React.Component {

  render() {
    function youtube_parser(url){
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
        var match = url.match(regExp);
        return (match&&match[7].length==11)? match[7] : false;
    }

    const opts = {
      height: '446',
      width: '100%',
      playerVars: { // https://developers.google.com/youtube/player_parameters
        autoplay: 0
      }
    };

    // Gets the content from the GraphQL query
    const post = get(this.props, 'data.contentfulVideo')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const videoId = youtube_parser(post.videoUrl)

    // Layout for the Video template
    return (
      <Layout location={this.props.location} >

        <Background>

          <Navigation />

          <Helmet title={post.title} />

          <ArticleColumn className="container">
            <ArticleBackground>
              <div className="container boxed-width justify-content-center is-direction-column">
                <InnerArticleBackground className="col-6 col-12-t">

                  <ArticleTypeWrapper>
                    <ArticleType>
                      Video
                    </ArticleType>
                  </ArticleTypeWrapper>

                  <BlogTitle>
                    {post.title}
                  </BlogTitle>

                  <PreviewParagraph className="tip-overview"
                    dangerouslySetInnerHTML={{
                      __html: post.description,
                    }}
                  />

                </InnerArticleBackground>
              </div>
            </ArticleBackground>
          </ArticleColumn>
          <PostContainer className="container boxed-width justify-content-center is-direction-column">
            <PostWrapper className="col-8 col-12-t">
              <YouTubeVideo
                videoId={videoId}
                opts={opts}
              />
            </PostWrapper>
          </PostContainer>

          <div className="container boxed-width justify-content-center is-direction-column">

            <div className="col-6 col-12-t">

              <SubscribeText>
                <h2>Subscribe</h2>
                <p>If you found this video useful, consider subscribing with the form at the bottom of this page. If you didn’t find this useful, let me know what I could have done better at <a href="mailto:callum@uiuxtips.com">callum@uiuxtips.com</a>.</p>
              </SubscribeText>

              <AuthorSection>
                <AuthorImage src="/content/callum.jpg" alt="Callum's profile picture" />
                <PostDate>Created by <span>Callum Harrod</span> on <span>{post.publishDate}</span></PostDate>
              </AuthorSection>

            </div>

          </div>

          <div className="padding-top-large">
            <EmailCtaSection />
          </div>

        </Background>
      </Layout>
    )
  }
}

export default VideoTemplate

// Query
export const pageQuery = graphql`
  query VideosBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulVideo(slug: { eq: $slug }) {
      slug
      title
      videoUrl
      description
      publishDate(formatString: "MMMM DD, YYYY")
    }
  }
`

// Styles
const PostContainer = styled.div `
  position: relative;

  @media (max-width: 768px) {
  }
`

const PostWrapper = styled.div `
  position: relative;
  z-index: 1;
`

const EmojiImgContainer = styled.div `
  max-width: 48px;
  height: 48px;
  width: 100%;
  margin-bottom: 12px;
  margin-top: auto;
  align-self: center;

  .gatsby-image-wrapper, img, picture, picture img {
    height: 42px;
    width: 100%;
    object-fit: contain;
  }
`

const EmojiImg = styled.div `
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 24px;

  img {
    max-width: 42px;
    height: auto;
    max-height: 42px;
    object-fit: contain;
  }
`

const PostTitle = styled.h1 `
  font-size: 40px;
  line-height: 50px;
  color: #0B072F;
`

// Article preview

const ArticleColumn = styled.div `
  width: 100%;
  display: flex;
`

const ArticleBackground = styled.div`
  display: flex;
  align-items: flex-start;
  color: #1a1a1a;
  text-decoration: none;
  position: relative;
  height: 100%;
  width: 100%;
`

const InnerArticleBackground = styled.div `
  display: flex;
  padding: 96px 0px 24px;
  position: relative;
  z-index: 2;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`

const BlogTitle = styled.h1`
  line-height: 1.5;
  margin-bottom: 12px;
  margin-top: 0;
  display: block;
`

const PreviewParagraph = styled.p `
  color: #9C9C9C;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 0;
`

const PreHeader = styled.div `
  display: flex;
  flex-direction: column;
`

const ArticleTypeWrapper = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
`

const ArticleType = styled.span `
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 900;
  margin-bottom: 0;
  color: #9C9C9C;
`

// Post image section
const PostImageSection = styled.div `
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 24px 0 48px;
  flex-wrap: wrap;
  align-items: flex-start;
`

// Tip Image
const TipImage = styled.div `
  order: 1;
  width: 100%;

  @media (max-width: 768px) {
    order: 1;
    margin: 0;
  }
`

// Good and bad
const WhatsBad = styled.div `
  order: 2;
  width: calc(50% - 12px);
  margin-right: 24px;
  color: #fff;
  display: flex;
  justify-content: center;

  ul, li {
   color: #fff;
  }

  ul {
    list-style-type: disc;
    list-style-position: inside;
  }
`
const WhatsGood = styled.div `
  order: 3;
  width: calc(50% - 12px);
  margin-left: auto;
  color: #fff;
  display: flex;
  justify-content: center;

  ul, li {
    color: #fff;
  }

  ul {
    list-style-type: disc;
    list-style-position: inside;
  }

`

const Background = styled.div `
`

const YouTubeVideo = styled(props => <YouTube {...props} />)`
  border-radius: 8px;
  margin-top: 24px;
`

const SubscribeText = styled.div `
  padding-top: 48px;

  h2 {
    color: #ffd265;
  }

  p {
    margin-bottom: 0;
  }
`

const AuthorSection = styled.div `
  padding-top: 48px;
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
`

const AuthorImage = styled.img `
  border-radius: 50%;
  max-width: 72px;
`

const PostDate = styled.p `
  margin-left: 24px;
  margin-bottom: 0;

  span {
    color: #fff;
  }
`